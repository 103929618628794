import axios from '@/api/service';
import URLs from '@/api/service/urls';
import { DevBotList, DevDependencies, QaDependencies } from '@/types/rpa-admin.types';
import { DevToQAPromotion, QAToProdPromotion } from '@/Rpa/types';
import { ActiveBot, ApprovalDependencyResponse } from '@/types/projects.types';

export async function getBotList(botSystemMnemonic: string): Promise<DevBotList> {
  const resp = await axios.get<DevBotList>(URLs.BotList, {
    params: {
      botSystem: botSystemMnemonic,
    },
  });
  return resp.data;
}

export async function getActiveBotList(): Promise<ActiveBot[]> {
  const botList = await axios.get(URLs.ActiveBotList, {});
  return botList.data;
}

export async function getDevDependencies(path: string, botSystemMnemonic: string): Promise<DevDependencies> {
  const resp = await axios.get<DevDependencies>(URLs.DevDependecies, {
    params: {
      path,
      botSystem: botSystemMnemonic,
    },
  });
  return resp.data;
}

export async function getQaDependencies(taskBotPath: string, botSystemMnemonic: string): Promise<QaDependencies> {
  const resp = await axios.get<QaDependencies>(URLs.QaDependecies, {
    params: { taskBotPath, botSystem: botSystemMnemonic },
  });
  return resp.data;
}

export async function promoteBotToQa(payload: DevToQAPromotion): Promise<number> {
  const resp = await axios.post(
    URLs.PromoteBotFromDevToQa,
    { payload },
  );
  return resp.data;
}

export async function promoteBotToProd(payload: QAToProdPromotion): Promise<number> {
  const resp = await axios.post(
    URLs.PromoteBotFromQaToProd,
    { payload },
  );
  return resp.data;
}

export async function getApprovalDependencies(
  packageId: string,
  botSystem: string,
): Promise<ApprovalDependencyResponse> {
  const resp = await axios.get(URLs.ApprovalDependencies, {
    params: { packageId, botSystem },
  });
  return resp.data;
}
