import { Component, Prop, Vue } from 'vue-property-decorator';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { FeatureFlagMnemonics } from '@/types/feature-flags.types';

@Component
export default class FeatureFlag extends Vue {
  @Prop({ type: String, required: true })
  public readonly name!: FeatureFlagMnemonics;

  // if auto is set, component will control rendering of children
  @Prop({ type: Boolean })
  public readonly auto!: boolean;

  protected render() {
    const enabled = FeatureFlagsModule.featureFlags[this.name].featureFlagEnabled;
    if (this.auto && !enabled) {
      return null;
    }
    return this.$scopedSlots.default?.({
      enabled,
    });
  }
}
