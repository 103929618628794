
import { Component, Vue } from 'vue-property-decorator';
import GridCell from '@/components/grid/GridCell';
import Grid from '@/components/grid/Grid';
import Placeholder from '@/views/Placeholder.vue';
import FullScreenForm from '@/components/FullScreenForm.vue';
import { RouteNames } from '@/constants';
import FeatureFlag from '../components/FeatureFlag';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { EnumsModule } from '@/store/modules/enums.module';
import * as rpaAPI from '@/api/rpa-admin.api';
import { DevBotList } from '@/types/rpa-admin.types';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';
import { openSnackbar } from '@/utils/components';

@Component({
  components: {
    Placeholder,
    Grid,
    GridCell,
    FullScreenForm,
    FeatureFlag,
    GDialog,
  },
})
export default class ProjectTemplateView extends Vue {
  public openUnavailableDialog = false;

  get closeRoute() {
    return {
      name: RouteNames.ProjectsList,
    };
  }

  get newRpaRoute() {
    return {
      name: RouteNames.NewRpaProject,
      params: {
        type: 'project',
      },
    };
  }

  get newCicdRoute() {
    return {
      name: RouteNames.NewProjectForm,
      params: {
        type: 'cicd',
      },
    };
  }

  get newApiRoute() {
    return {
      name: RouteNames.NewProjectForm,
      params: {
        type: 'api',
      },
    };
  }

  get kongEnabled(): boolean {
    return FeatureFlagsModule.kongEnabled;
  }

  get a360Enabled(): boolean {
    return FeatureFlagsModule.a360Enabled;
  }

  showUnavailableDialog(): void {
    this.openUnavailableDialog = true;
  }

  async created(): Promise<void> {
    const { A360 } = EnumsModule.enums.botSystems;
    const botSystems = Object.values(EnumsModule.enums.botSystems).filter((system) => {
      if (system.mnemonic === A360.mnemonic) {
        return this.a360Enabled;
      }
      return true;
    }).map((system) => system.mnemonic);
    botSystems.forEach(v => {
      rpaAPI
        .getBotList(v.toString())
        .then(botList => {
          ProjectDetailsRpaModule.setSelectBotStatus({ botList, botSystemMnemonic: v });
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          // openSnackbar.call(this, e.response.data.error, { type: 'error' });
        });
    });
  }
}
